import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
// import flickity from 'flickity'
import Flickity from 'react-flickity-component'
import Fade from 'react-reveal/Fade';
import ReactPlayer from 'react-player'
import SVG, { Props as SVGProps } from 'react-inlinesvg';

import Layout from "../components/layout"
import SEO from "../components/seo"

import playIcon from "../images/playicon.svg"

class ProjectTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = { lightBoxVisible: false, isPlaying: false };

    this.trailerPlayer = React.createRef();
    this.headerVideo = React.createRef();
  }
  
  toggleLightBox() {
    if(this.state.lightBoxVisible) { // Turn off
      this.setState({ lightBoxVisible: false, isPlaying: false });
      this.headerVideo.current.play();
      if (typeof document !== `undefined`) document.querySelector("body").classList.remove("overflow-hidden");
    } else { // Turn on
      this.setState({ lightBoxVisible: true });
      this.headerVideo.current.pause();
      if (typeof document !== `undefined`) document.querySelector("body").classList.add("overflow-hidden");
    }
  }

  render() {
    const project = this.props.data.contentfulProject;

    return(
      <Layout>
        <SEO 
          title={ project.title } 
          description={ project.shortDescription } 
          metaImage={ project?.socialImage?.file?.url }
        />
        
        {
          project.trailerURL ?
          <div className={`lightbox position-fixed w-100 h-100 d-flex align-items-center ${ this.state.lightBoxVisible ? "" : "hidden" }`} onClick={ () => { this.toggleLightBox() } }>
            <Container fluid>
              <Row>
                <Col xs={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1 }}>
                  <div className="player-wrapper">
                    <ReactPlayer ref={ this.trailerPlayer } playing={ this.state.isPlaying } className="react-player" onPlay={ () => { this.setState({ isPlaying: true}) } } controls url={ project.trailerURL } />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          :
          ""
        }

        <section id="project-header" className="bg-brown vw-100 vh-100 py-4 py-md-5">
          <div className="feature-video d-flex align-items-center justify-content-center">
            <video ref={ this.headerVideo } className="project-video" autoPlay={ true } playsInline muted loop controls={ false }>
              <source src={ `https:${project.trailer.file.url}` } type="video/mp4" />
            </video>
            <div className="feature-video-overlay"/>
          </div>
        </section>

        <section id="content" className="bg-pink vw-100 py-5 position-relative">
          <Container>
            <div className="d-flex flex-column justify-content-end justify-content-lg-between">
              <Row className="mb-lg-5 pb-lg-5">
                <Col lg={{ span: 5 }} className="pr-lg-0">
                  <div className="feature-title color-brown">
                    <Fade bottom>
                      <h2 className="mb-3">{ project.title }</h2>
                    </Fade>
                  </div>
                </Col>
                <Col lg={{ span: 7 }} className="pl-lg-5">
                  <Row>
                    <Col lg={{ span: 3 }} className="mt-lg-3 mt-xl-4 pr-lg-0">
                      <div className="details color-brown mb-4 mb-lg-0">
                        <Fade bottom>
                          <p className="mb-0">DIRECTED BY</p>
                          <p className="mb-0">{ project.directedBy }</p>
                        </Fade>
                      </div>
                    </Col>
                    <Col lg={{ span: 3 }} className="mt-lg-3 mt-xl-4 pr-lg-0">
                      <div className="details color-brown mb-4 mb-lg-0">
                        <Fade bottom>
                          <p className="mb-0">WRITTEN BY</p>
                          <p className="mb-0">{ project.writtenBy }</p>
                        </Fade>
                      </div>
                    </Col>
                    <Col lg={{ span: 6 }} className="mt-lg-3 mt-xl-4 pr-lg-0">
                      <div className="details color-brown mb-4 mb-lg-0">
                        <Fade bottom>
                          <p className="mb-0">STARRING</p>
                          <p className="mb-0">{ project.starring }</p>
                        </Fade>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mt-lg-5 mb-lg-5 pb-lg-5">
                <Col lg={{ span: 5 }}>
                  {
                    project.trailerURL || project.seriesURL ?
                      <>
                        <div className="my-lg-4">
                          <Fade bottom>
                            <div className="d-flex justify-content-between">
                              {
                                project.trailerURL ?
                                <div onClick={() => { this.toggleLightBox() }} className="watch-trailer details color-brown">
                                  {/* <img className="play-icon pr-2" src={ playIcon } /> */}
                                  <SVG className="play-icon pr-2" src={ playIcon } />
                                  <span className="play-text">WATCH TRAILER</span>
                                </div>
                                :
                                ""
                              }
                              {
                                project.watchURL ?
                                <a href={project.watchURL} target="_blank" className="watch-trailer details color-brown">
                                  {/* <img className="play-icon pr-2" src={ playIcon } /> */}
                                  <SVG className="play-icon pr-2" src={ playIcon } />
                                  <span className="play-text">{ project.watchCopy.toUpperCase() || "WATCH NOW" }</span>
                                </a>
                                :
                                ""
                              }
                            </div>
                          </Fade>
                        </div>  
                        <hr className="project-hr mt-lg-0 mb-lg-5" />
                      </>
                    :
                    ""
                  }
                  {
                    project.laurels ?
                    <>
                      <div className="laurels">
                        {
                          project.laurels.map((laurel, index) => <img key={`laurel-${index}`} className={`laurel my-2 ${ index === 0 || index === project.laurels.length - 1 ? "" : "mx-2" }`} src={ laurel.fluid.src } />)
                        }
                      </div>
                      <hr className="project-hr mb-lg-0 mt-lg-5" />
                    </>
                    :
                    ""
                  }
                </Col>
                
                <Col lg={{ span: 7 }}>
                  <Fade bottom>
                    <p className="copy color-brown">{ project.description.description }</p>
                  </Fade>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
        
        {
          project.reviews ?
          <section id="testimonials" style={{"background": project.pressBackgroundColor || "" }} className="bg-pink py-5">
            <Flickity options={{ wrapAround: true, prevNextButtons: false, pageDots: false, autoPlay: true }}>
              {
                project.reviews.map((review, index) => (
                  <div className="mx-3 p-4 testimonial text-center" key={`babe-nation-review-${index}`}>
                    <div className="testimonial-author-img d-flex align-items-center justify-content-center">
                      <img src={`https:${review.authorLogo.file.url}`} />
                    </div>
                    <p className="testimonial-date color-white mt-5">AUGUST 30, 2019</p>
                    <p className="testimonial-copy color-white">"{ review.copy.copy.length <= 50 ? review.copy.copy : `${review.copy.copy.slice(0, 50)}...` }"</p>
                    {
                      review.link ? 
                      <a href={review.link} className="testimonial-link pb-4 pr-4">Read more</a> :
                      ""
                    }
                  </div>
                ))
              }
            </Flickity>
          </section>
          :
          ""
        }
      </Layout>
    )
  }
}

export default ProjectTemplate

export const projectQuery = graphql`
  query ContentfulProjectBySlug($slug: String!) {
    contentfulProject( slug: { eq: $slug }) {
      title
      directedBy
      writtenBy
      starring
      description {
        description
      }
      shortDescription {
        shortDescription
      }
      socialImage {
        file {
          url
        }
      }
      trailer {
        file {
          url
        }
      }
      trailerURL
      watchURL
      watchCopy
      laurels {
        fluid(maxWidth: 256) {
          ...GatsbyContentfulFluid
        }
      }
      pressBackgroundColor
      reviews {
        copy {
          copy
        }
        author
        authorLogo {
          file {
            url
          }
        }
        link
      }
    }
  }
`